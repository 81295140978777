.icons {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotateIcons 10s linear infinite; /* Add rotation animation */
}

/* @media (max-width: 768px) {
  .icons {
    width: 50%;
    height: 80%;
  }
} */

@keyframes rotateIcons {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.icons span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: 0 0;
  transform: rotate(var(--degrees)) translate(0, -20vh);
}

.icons img {
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
}
.tech-stack {
  position: absolute; /* Keep the h1 centered and static */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
